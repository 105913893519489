<template>
  <div>
    <b-card>
      <!-- car2 -->
      <b-row>
        <b-col sm="12">
          <!-- chart1 -->
          <b-card no-body class="border">
            <b-row class="match-height">
              <b-col md="6" lg="6">
                <apex-data-bar-chart />
              </b-col>

              <b-col md="6" lg="6">
                <apex-donut-chart />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-row>
            <b-col cols="3">
              <b-form-group label-cols="4" label-cols-lg="6" label="Data Export :" label-for="select" size="sm">
                <b-form-select v-model="selectedForm" id="select-data" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a"> Tabular</option>
                  <option value="b"> Chart</option>
                  <option value="b"> Maps</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group label-cols="4" label-cols-lg="6" label="Data Format :" label-for="select" size="sm">
                <b-form-select v-model="selectedForm" id="select-data" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a"> CSV</option>
                  <option value="b"> PDF</option>
                  <option value="b"> PNG</option>
                  <option value="b"> JPG</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4"> </b-col>

            <b-col cols="2 ">
              <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Download
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BForm, BCard, BCardHeader, BCardTitle, BFormDatepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import ApexDonutChart from "@/views/charts-and-maps/charts/apex-chart/ApexDonutChart.vue";
import ApexDataBarChart from "@/views/charts-and-maps/charts/apex-chart/ApexDataBarChart.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,

    vSelect,
    BFormDatepicker,
    ApexDonutChart,
    ApexDataBarChart,
  },

  data: function() {
    return {
      table_fields: ["bulan", "30"],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    handleChange(value) {
      console.log(`selected ${value}`);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
<style scoped>
.pad1 {
  margin-right: 2px;
}
.pad1 {
  margin-right: 2px;
  margin-left: 2px;
}
</style>
