<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <h6 class="text-dark">Data Raw Visualization</h6>
          <hr />
          <b-row>
            <b-col sm="2">
              <b-form-group label="Resource" label-for="select-station" size="sm" inline id="select-form">
                <b-form-select v-model="selectedForm" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">ME45</option>
                  <option value="b">ME48</option>
                  <option value="c">Metar</option>
                  <option value="d">Pilot Balon</option>
                  <option value="e">FKLIM</option>
                  <option value="b">Iklim Mikro</option>
                  <option value="b">AGM 1A</option>
                  <option value="b">AGM 1B</option>
                  <option value="b">Suhu Tanah</option>
                  <option value="b">OP Penguapan</option>
                  <option value="b">Piche Penguapan</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Region" label-for="select-wmo" size="sm">
                <b-form-select v-model="t_wmoid" class="" id="select-wmo" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">Region I</option>
                  <option value="b">Region II</option>
                  <option value="c">Region III</option>
                  <option value="d">Region IV</option>
                  <option value="e">Region V</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Provinsi" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a"> Nanggroe Aceh Darussalam</option>
                  <option value="b"> Sumatera Utara</option>
                  <option value="c"> Sumatera Selatan</option>
                  <option value="d"> Sumatera Barat</option>
                  <option value="e"> Bengkulu</option>
                  <option value="f"> Riau</option>
                  <option value="g"> Kepulauan Riau </option>
                  <option value="h"> Jambi</option>
                  <option value="i"> Lampung</option>
                  <option value="j"> Bangka Belitung</option>
                  <option value="k"> Kalimantan Barat</option>
                  <option value="l"> Kalimantan Timur</option>
                  <option value="m"> Kalimantan Selatan</option>
                  <option value="n"> Kalimantan Tengah</option>
                  <option value="o"> Kalimantan Utara</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Kabupaten" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">Pilih</option>
                  <option value="1">Kabupaten 1</option>
                  <option value="2">Kabupaten 1</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Date" label-for="select-date" size="sm">
                <a-range-picker @change="onChange" />
              </b-form-group>
            </b-col>

            <b-col sm="1 mt-2">
              <!-- <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Filter</b-button> -->
              <b-button @click="onFetch" variant="primary" class="ml-sm-6" size="sm">Submit</b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <tabdataraw v-if="choosenForm == 'a'"> </tabdataraw>
    <!-- <magnet v-if="choosenForm == 'b'"> </magnet> -->
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput } from "bootstrap-vue";
//import Swal from "sweetalert2";

import Tabdataraw from "./Componendataraw/Tabdataraw.vue";
// import Magnet from "./geomonitoringcomponent/magnet.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    //Swal,
    Tabdataraw,
  },
  data: function() {
    return {
      backgroundLoading: "#ff8000",
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_wmoid: "",
      t_date: "",
      option: "",
    };
  },

  methods: {
    onFetch() {
      // Swal.fire({
      //   title: "Do you want to save the changes?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Save",
      //   denyButtonText: `Don't save`,
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     Swal.fire("Saved!", "", "success");
      //   } else if (result.isDenied) {
      //     Swal.fire("Changes are not saved", "", "info");
      //   }
      // });

      this.choosenForm = this.selectedForm;
    },
  },
  openLoadingBackground() {
    // this.$vs.loading({ background: this.backgroundLoading, color: "#ff8000" });
    // setTimeout(() => {
    //   this.$vs.loading.close();
    // }, 5000);
  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ant-input {
  border: 2px solid #6e6b7b !important;
}
</style>
