<template>
  <div>
    <b-card no-body>
      <b-row c>
        <b-col sm="12">
          <b-row class="mt-n1">
            <b-col cols="12" class="float-right">
              <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" split right size="sm" text="View" variant="success" class="float-right mt-n2">
                <b-dropdown-item>RBI</b-dropdown-item>
                <b-dropdown-item>QGIS</b-dropdown-item>
                <b-dropdown-item>OSM</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-card no-body class="border p-1 mt-1">
            <div style="height: 400px; width: 100%">
              <l-map :zoom="zoom" :center="center" style="z-index:0">
                <l-marker :lat-lng="center"></l-marker>
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              </l-map>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Export :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> Tabular</option>
                <option value="b"> Chart</option>
                <option value="b"> Maps</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Format :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> CSV</option>
                <option value="b"> PDF</option>
                <option value="b"> PNG</option>
                <option value="b"> JPG</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4"> </b-col>

          <b-col cols="2 ">
            <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              Download
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import Ripple from "vue-ripple-directive";
import { BTable, BButton, BFormGroup, BFormSelect, BCol, BCard, BForm, BRow, BDropdown, BDropdownItem } from "bootstrap-vue";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    BTable,
    BButton,
    BFormGroup,
    BFormSelect,
    BCol,
    BCard,
    BForm,
    BRow,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 12,
      center: [-6.93, 107.6],
      bounds: null,
    };
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 400px;
  }
}
</style>
