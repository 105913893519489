<template>
  <div>
    <b-card-body class="w-100 h-100">
      <div class="table-responsive text-nowrap">
        <b-table striped hover responsive :items="table_items" :fields="table_fields" small table-variant="success" t>
          <!-- template -->
          <template v-for="field in date_fields" v-slot:[`cell(${field})`]="data">
            <div :key="field">
              <feather-icon icon="CheckIcon" style="color: blue" v-if="data.value" />
              <feather-icon icon="XIcon" style="color: red" v-else />
            </div>
          </template>
        </b-table>
      </div>
    </b-card-body>
    <hr />

    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Export :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> Tabular</option>
                <option value="b"> Chart</option>
                <option value="b"> Maps</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Format :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> CSV</option>
                <option value="b"> PDF</option>
                <option value="b"> PNG</option>
                <option value="b"> JPG</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4"> </b-col>

          <b-col cols="2 ">
            <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              Download
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BButton, BFormGroup, BFormSelect, BCol, BCard, BForm, BRow } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BFormGroup,
    BFormSelect,
    BCol,
    BCard,
    BForm,
    BRow,
  },

  data() {
    return {
      date_fields: [
        // untuk template cell tanggal
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      table_items: [
        {
          id: 96001,
          nama_stasiun: "Stasiun Metereologi Malkussaleh",
          opr_hours: 24,
          "01": true,
          "02": false,
          "03": true,
          availability: 0,
        },
        {
          id: 96002,
          nama_stasiun: "Stasiun Metereologi Bandung",
          opr_hours: 12,
          "01": true,
          "02": true,
          "03": true,
          availability: 0,
        },
        {
          id: 96003,
          nama_stasiun: "Stasiun Metereologi Jakarta",
          opr_hours: 9,
          "01": false,
          "02": true,
          "03": true,
          "07": true,
          "15": true,
          availability: 0,
        },
      ],
      table_fields: [
        "id",
        "nama_stasiun",
        "opr_hours",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "availability",
      ],
    };
  },
};
</script>
